<template lang="pug">
    .about
        v-container
            v-row(dense)
                v-col(cols="12")
                    v-card(outlined color="#f5f5f5").pa-3
                        p.mb-0 This site gathers property listings in Bansko, Bulgaria multiple times every day, from the major local real estate agency websites.
                v-col(cols="12")
                  .text-center
                      v-btn.mt-md-3(depressed block to="faq" color="primary")
                        v-icon.mr-2() mdi-information-outline
                        | FAQ (Questions)
                      
                      v-btn.mt-md-3(depressed block to="search" color="accent")
                        v-icon.mr-2() mdi-magnify
                        | Search

                v-col(cols="12" v-if="$vuetify.breakpoint.mdAndUp")
                    Promo

                v-col(cols="12" v-if="$vuetify.breakpoint.mdAndUp")
                  v-btn(plain to="privacy") Privacy Policy
                                
</template>

<script>
const Promo = () => import("@/components/Promo");
export default {
  name: "About",
  components: { Promo },
  data() {
    return {};
  }
};
</script>
